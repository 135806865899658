.badges-container {
  display: flex;
  max-width: 20%;
}

.badge-wrapper {
  padding: 0 10px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  min-width: fit-content;
}

.badge,
#subscription-timeline .badge {
  text-transform: uppercase;
  letter-spacing: 1.1px;
  box-shadow: none;
  font-size: 12px;
  height: 18px;
  border-radius: 16px;
}

.interval {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.9);
}

.future-interval {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.4);
}

.status-timeline-badge {
  height: auto !important;
  display: inline-block;
  padding: 4px 6px;
  font-weight: 500;
}

.badge:active {
  box-shadow: none;
  border: none;
  outline: none;
}

.badge:hover {
  box-shadow: none;
  border: none;
  outline: none;
}

.badge.suspended,
.status-timeline-badge.suspended {
  background-color: rgb(224, 182, 66);
  color: #000000;
}

.badge.pending,
.status-timeline-badge.pending {
  background-color: #2962d4;
  color: #ffffff;
}

.badge.active,
.status-timeline-badge.active {
  background-color: #39805f;
  color: #ffffff;
}

.badge.cancelled,
.status-timeline-badge.cancelled {
  background-color: rgb(182, 15, 0);
  color: #ffffff;
}

.badge.expired,
.status-timeline-badge.expired {
  background-color: rgb(87, 94, 99);
  color: #ffffff;
}

.pending-cancellation {
  background-color: rgb(224, 182, 66);
  color: #000000;
}

.badge.primary {
  background-color: rgb(18, 105, 186);
  color: #fff;
}

.status-timeline-badge.current-status {
  background-color: #52a78d;
  color: #ffffff;
}

span.MuiTypography-overline {
  line-height: unset;
}

.with-border {
  border-bottom: 1px solid #ccc;
}

.figure-cards {
  margin-bottom: 1rem;
}

tspan {
  user-select: none;
}

.material-icons.MuiIcon-fontSizeSmall {
  font-size: 12px;
}

.zds-table {
  background: white;
}

.zds-table thead th {
  cursor: pointer;
  position: relative;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.9);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 8px 16px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.details-div td {
  border-bottom: none;
  background-color: rgb(239, 239, 239);
}

ul {
  margin: 0;
  padding: 0;
}

.invisible {
  display: none;
}

.field-group,
.flex-align-item-center {
  display: flex;
  align-items: center;
}

.field-group > * {
  padding: 0.5rem;
}

.view-by {
  margin-bottom: 1rem;
}

.charge-label {
  cursor: pointer;
}

.MuiTypography-root.charge-label {
  margin-bottom: 0.5rem;
  display: inline-block;
}

.action-icons span {
  cursor: pointer;
}

.date-col {
  text-align: right !important;
}

.order-number-col {
  color: #006699;
  padding-right: 1%;
  display: flex;
}

.collapsible-btn {
  cursor: pointer;
}

.order-number-span {
  min-width: 85px;
  display: flex;
  align-items: center;
}

.future-term {
  background-color: rgb(245, 245, 245);
  color: rgba(0, 0, 0, 0.4);
}

.current-term {
  background-color: #52a78d;
  color: white;
}

.past-term {
  background-color: white;
  color: rgba(0, 0, 0, 0.9);
}

.badge-td {
  text-align: right;
}

.current-term-badge {
  background: white;
  color: #52a78d;
  min-width: 74%;
  font-size: 10px;
  font-weight: bold;
  height: 100%;
}

.term-history-row td {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;

  letter-spacing: 0.15px;
  padding: 12px;
}

.remove-badge {
  background-color: grey;
  color: white;
  height: 50%;
  font-size: 12px;
  font-weight: bold;
  margin: 4px 25px 0 0;
}

.prod-badge {
  margin: 4px 25px 0 0;
}

.inactive-badge {
  background: rgba(0, 0, 0, 0.16);
  color: rgba(0, 0, 0, 0.9);
  height: 50%;
  font-size: 12px;
  font-weight: bold;
  margin: 4px 25px 0 0;
}

.active-badge {
  background-color: #39805f;
  color: #ffffff;
  height: 50%;
  font-size: 12px;
  font-weight: bold;
}

.active-badge {
  background-color: #39805f;
  color: #ffffff;
  height: 50%;
  font-size: 12px;
  font-weight: bold;
}

.controls-div {
  margin-left: auto !important;
  display: flex;
}
.row-content {
  padding-top: 8px !important;
}

.align-right {
  text-align: right !important;
}

h6.header {
  line-height: unset;
}

.field-label {
  line-height: 2 !important;
  display: inline-block;
}

.field-label span {
  line-height: 1.75 !important;
  display: inline-block;
}

.created-time {
  font-size: 12px;
  color: grey;
}

.set-activation-desc {
  font-family: inherit;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.9);
  margin-bottom: 21px;
  margin-top: 13px;
  font-weight: 400;
}

.search-icon {
  padding: 7px 4px 0;
}

.attachment-header-div {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  height: 40px;
}

.search-product {
  border-top: 1px solid rgba(224, 224, 224, 1);
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.search-product .MuiTablePagination-selectLabel {
  display: none;
}
.search-product [class*="MuiTablePagination-select"] {
  display: none;
}
.search-product td.MuiTablePagination-root {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  border-top: 1px solid rgb(224, 224, 224);
}

.history-search {
  display: flex;
}

.product-filter {
  margin-left: 5%;
  margin-top: 0.3%;
  cursor: pointer;
}

.table-chart-icon {
  margin: auto;
  cursor: pointer;
}

.figure-card-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hover-underline-text:hover {
  text-decoration: underline;
}

.tooltip-action {
  color: rgba(145, 191, 247, 1);
  margin-left: 14px;
  white-space: nowrap;
}

.draft-badge,
.preview-badge {
  color: rgba(0, 0, 0, 0.9);
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 1.1px;

  border-radius: 100000px;
}

.draft-badge {
  background: rgba(0, 0, 0, 0.04);
}

.preview-badge {
  background: rgba(0, 0, 0, 0.16);
}

.uppercase {
  text-transform: uppercase;
}

.badge-text {
  white-space: nowrap;
}

.preview-page-wrapper {
  margin-top: 20px;
}

.specific-date-field {
  margin-left: 7px;
}

.tooltip-body td {
  vertical-align: top;
}

.tooltip-body th {
  text-transform: uppercase;
}

span.field-label {
  text-transform: none !important;
  letter-spacing: 0 !important;
  font-size: 13px;
}

.posted-badge {
  background: #39805f !important;
  color: white !important;
}
[aria-labelledby="segments-header"] {
  left: 3%;
  max-width: 1400px !important;
}

.reversed-badge {
  background: #cf4a19 !important;
  color: white !important;
}

.link-text {
  color: #0d4ac3 !important;
  cursor: pointer;
}

.badge.version-badge {
  background: rgb(13, 74, 195);
  color: #ffffff;
  min-width: 40px;
}

.badge.version-badge-expired {
  background-color: rgba(0, 0, 0, 0.08);
  color: #000000;
}

.text-red {
  color: #b60f00;
}

.badge.draft {
  background-color: rgba(0, 0, 0, 0.08) !important;
  color: #000000 !important;
}
.row-header h6.header {
  font-weight: 600;
}
.MuiDialogContent-root.MuiDialogContent-dividers .fjLdva.data-row {
  padding: 10px 0 !important;
}

@media only screen and (max-width: 1550px) {
  .badges-container {
    max-width: 25%;
  }
  .badge-wrapper {
    padding: 0 5px;
  }
}

.versionlist-pop-up {
  width: 190px;
}

.versionlist-pop-up .created-date {
  font-size: 12px;
}

.version-list-item {
  display: inline-block;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.9);
  font-size: 13px;
  padding-bottom: 5px;
}
.version-list-active {
  font-weight: 600;
}
